import React from 'react';
import { Graph, Photoabout, aboutUs, abouttext, object_img } from '../assests';

const AboutUs = () => {
    return (
        <div style={{ backgroundColor: "#29B06710" }}>
            <div style={{ alignItems: "flex-end", display: "flex" }}>
                <div style={{ height: 500, width: 750, backgroundColor: "#fff" }} >
                    {/* <p style={{color: "#C0C0C0", fontWeight: "bold", fontSize: "40px"}} >Crafting Fibre Innovations<br/> Since '98: <br/>Building Tomorrow, <br/>Today.</p> */}
                    {/* <p style={{color: "#C0C0C0", fontWeight: "bold", fontSize: "40px"}}>Since '98:</p>
                    <p style={{color: "#C0C0C0", fontWeight: "bold", fontSize: "40px"}}>Building Tomorrow,</p>
                    <p style={{color: "#C0C0C0", fontWeight: "bold", fontSize: "40px"}}>Today.</p> */}
                    <img src={abouttext} style={{ height: 330, width: 660, marginLeft: 50, marginTop: 70 }}/>
                </div>
                <img src={aboutUs} style={{ height: 500, width: 700, alignSelf: "flex-end" }} />
            </div>
            <div style={{ alignItems: "flex-end", display: "flex" }}>
                <img src={Photoabout} style={{ height: 500, width: 700, marginLeft: 50 }} />
                <div style={{ width: 500, marginLeft: 50 }}>
                    <h3>Our Company Overview</h3>
                    <p>ACE Engineerings & Chemicals Pvt. Ltd. is a leading manufacturer for FRP, GRP, Injection Molding, Vacuum Forming, SMC/DMC, Pultrusion, RTM, Spray Up Sheet Metal and prototype components.
                        Ever since its establishment in 1998, AECPL has given quality products which has set new benchmarks for these technologies.</p>
                    <p>AECPL has always given customer satisfaction top priority and this enables us to be the preferred supplier for all our valued customers.</p>
                    <p>Our objective is to fit seamlessly within your organization, complimenting in-house product development team by providing link between your company and the end user.</p>
                    <p>We are committed to provide superior quality of components/products and services in the shortest lead-time. At AECPL, we strive to surpass your expectation with our quality and performance.</p>
                    <p>We strive to maintain the highest standards of QUALITY…It is because of your trust & belief in us that we can achieve continuous growth…</p>
                </div>
            </div>
            <img src={Graph} style={{ height: 500, width: 1200, alignSelf: "center", marginLeft: 100, marginTop: 50, borderWidth: 1, borderColor: "#000" }} />
        </div>
    );
};

export default AboutUs;