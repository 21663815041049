import { useState } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import LandingPage from './Screens/LandingPage';
import ProductPage from './Screens/ProductPage';
import Technologies from './Screens/Technologies';
import AboutUs from './Screens/AboutUs';
import ContactUs from './Screens/ContactUs';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <Router>
      {/* <Header /> */}
        <LandingPage />
        {/* <ProductPage />
        <Technologies />
        <AboutUs />
        <ContactUs /> */}
        {/* <Footer /> */}
    </Router>
  );
}

export default App;


