import React, { useState } from 'react';
import { CircleLogo, Major1, Major2, ProductIcon1, ProductIcon2, ProductIcon3, ProductIcon4, ProductIcon5, ProductIcon7, TechnologiesIcon, landing, logo } from '../assests';
import Header from '../Header';
import { Link } from 'react-router-dom';
import Home from './Home';
import ProductPage from './ProductPage';
import Technologies from './Technologies';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import './Home.css';
const LandingPage = () => {
    const [active, setActive] = useState("Home")
    return (
        <div>
            <header className='header'>
                {/* Logo and Company Name */}
                <div className='logo' style={{ display: "flex", alignItems: "center" }}>
                    <img src={CircleLogo} style={{ height: 80, width: 100, marginLeft: 80, backgroundColor: "red" }} />
                    <p style={{ fontSize: 20, fontWeight: "500" }}>ACE Engineerings & <br />Chemicals Pvt Ltd</p>
                    {/* <span>Company Name</span> */}
                </div>

                {/* Navigation Links */}
                <nav>
                    <ul>
                        <li><Link to="./Screens/LandingPage" onClick={() => { setActive("Home") }}>Home</Link></li>
                        <li><Link to="./Screens/ProductPage" onClick={() => { setActive("Products") }}>Products</Link></li>
                        <li><Link to="./Screens/technologies" onClick={() => { setActive("Technologies") }}>Technologies</Link></li>
                        <li><Link to="./Screens/about" onClick={() => { setActive("About") }}>About Us</Link></li>
                        <li><Link to="./Screens/ContactUs" onClick={() => { setActive("Contact") }}>Contact Us</Link></li>
                    </ul>
                </nav>
            </header>
            {active === "Home" && <Home />}
            {active === "Products" && <ProductPage />}
            {active === "Technologies" && <Technologies />}
            {active === "About" && <AboutUs />}
            {active === "Contact" && <ContactUs />}
            <footer>
                <div className="row" style={{ marginTop: 100, marginBottom: 100 }}>
                    {/* Content for the first row */}
                    <div style={{ width: 600, display: "flex", alignItems: "center" }}>
                        <img src={CircleLogo} style={{ height: 100, width: 120, marginLeft: 50 }} />
                        <p style={{ fontSize: 25, fontWeight: "500" }}>ACE Engineerings & <br />Chemicals Pvt Ltd</p>
                    </div>
                    <div style={{ width: 450 }}>
                        <h2>COMPANY</h2>
                        <h4 onClick={() => { setActive("Products") }}> Product</h4>
                        <h4 onClick={() => { setActive("Technologies") }}>Technologies</h4>
                        <h4 onClick={() => { setActive("About") }}>About US</h4>
                        <h4 onClick={() => { setActive("Contact") }}>Contact US</h4>
                        <p></p>
                    </div>
                    <div style={{ width: 500, textAlign: "left" }}>
                        <h2>CONTACT</h2>
                        <h4>Phone no:</h4>
                        {/* <img/> */}
                        <p>9770501439</p>
                        <h4>Address :</h4>
                        {/* <img/> */}
                        <p>No. 659/1, Rau-Rangwasa Road, Kishkindha Dham <br />Industrial  Area Rau Dist. Indore, Madhya Pradesh, <br /> India - Pin 453331</p>
                    </div>

                </div>
            </footer>
        </div>
    );
};

export default LandingPage;