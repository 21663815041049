import React from 'react';
import { Tech1, } from '../assests';

const ContactUs = () => {
    return (
        <div style={{ marginLeft: "30px", backgroundColor: "#29B06710" }}>
            <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                <div style={{ padding: 5, textAlign: "center", width: 500, marginRight: 100 }}>
                    <h3>UNIT 1</h3>
                    <h4>No. 659/1, Rau-Rangwasa Road, Kishkindha Dham Industrial Area Rau</h4>
                    <h4>Dist. Indore, Madhya Pradesh,</h4>
                    <h4>India- Pin - 453331</h4>
                    <h4>Ph: 91-9770501439</h4>
                </div>
                <div>
                    <img src={Tech1} style={{ height: 200, width: 500 }} />
                </div>
            </div>
            <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>

                <div>
                    <img src={Tech1} style={{ height: 200, width: 500 }} />
                </div>
                <div style={{ padding: 5, textAlign: "left", width: 500, marginRight: 100 }}>
                    <h3 style={{ textAlign: "center" }}>UNIT 2</h3>
                </div>
            </div>
            <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                <div style={{ padding: 5, textAlign: "left", width: 500, marginRight: 100 }}>
                    <h3 style={{ textAlign: "center" }}>UNIT 3</h3>
                </div>
                <div>
                    <img src={Tech1} style={{ height: 200, width: 500 }} />
                </div>
            </div>
            <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                <div>
                    <img src={Tech1} style={{ height: 200, width: 500 }} />
                </div>
                <div style={{ padding: 5, textAlign: "center", width: 500, }}>
                    <h3>UNIT 4</h3>
                    <h4>Survey no. 244/2, Rau - Pithampur Rd, Bheslay, </h4>
                    <h4>Sonway, Madhya Pradesh</h4>
                    <h4>India- Pin - 453332</h4>
                    <h4>Ph: 91-9770501439</h4>
                </div>
            </div>
            <div style={{ textAlign: "center", paddingBottom: 15 }}>
                <h1>ACE Engineerings & Chemicals Pvt Ltd</h1>
                <h3>No. 659/1, Rau-Rangwasa Road, Kishkindha Dham Industrial Area Rau</h3>
                <h3>Dist. Indore, Madhya Pradesh,</h3>
                <h3>India- Pin - 453331</h3>
                <h3>Ph: 91-731-2856527, Fax: 91-731-2857481</h3>
                <h3>Email: crm@aceengineerings.com</h3>
                <h3>Web: https://aceengineerings.com</h3>
            </div>
        </div>
    );
};

export default ContactUs;