import React, { useState } from 'react';
import './Product.css';
import { AutomotiveImage, BatteryImage, ExcavatorImage, HoodImage, MudImage, ProductIcon, ProductImage, TensionImage } from '../assests';
import { data } from "./Data"

const ProductPage = () => {
    const [isSelected, setisSelected] = useState(1)
    const dataList = data;
    const filteredData = filterDataById(isSelected); // Filter data with id 2
    function filterDataById(id) {
        return dataList.filter(item => item.id === id);
    }
    // Function to chunk array into groups of n items
    function chunkArray(array, chunkSize) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }
    const rowsOfThree = chunkArray(filteredData, 3);
    console.log("@@@ filteredData[0].headerImage================", filteredData[0].headerImage)
    return (
        <div style={{backgroundColor: "#29B06710"}}>
            <div>
                <img src={filteredData[0].headerImage === "" ? ProductImage : filteredData[0].headerImage} style={{ height: "730px", width: "100%" }} />
            </div>
            <h1 style={{textAlign: "center"}}>Explore our All Products</h1>
            <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ textAlign: "left" }}>
                    <div style={{ flexDirection: "row", display: "flex", alignItems: "center", marginLeft: 20 }} onClick={() => setisSelected(1)}>
                        <img src={MudImage} style={{ height: 35, width: 45 }} />
                        <h4 style={{ marginLeft: 20 }}>Mud Suppression Flaps</h4>
                    </div>
                    <div style={{ height: 3, width: 270, backgroundColor: isSelected === 1 ? "#E58E1A" : "#C8C8C8", marginLeft: 20 }} />
                    <div style={{ flexDirection: "row", display: "flex", alignItems: "center", marginLeft: 20 }} onClick={() => setisSelected(2)}>
                        <img src={AutomotiveImage} style={{ height: 35, width: 45 }} />
                        <h4 style={{ marginLeft: 20 }}>FRP Automotive Products</h4>
                    </div>
                    <div style={{ height: 3, width: 270, backgroundColor: isSelected === 2 ? "#E58E1A" : "#C8C8C8", marginLeft: 20 }} />
                    <div style={{ flexDirection: "row", display: "flex", alignItems: "center", marginLeft: 20 }} onClick={() => setisSelected(3)}>
                        <img src={ExcavatorImage} style={{ height: 35, width: 45 }} />
                        <h4 style={{ marginLeft: 20 }}>Excavator Parts</h4>
                    </div>
                    <div style={{ height: 3, width: 270, backgroundColor: isSelected === 3 ? "#E58E1A" : "#C8C8C8", marginLeft: 20 }} />
                    <div style={{ flexDirection: "row", display: "flex", alignItems: "center", marginLeft: 20 }} onClick={() => setisSelected(4)}>
                        <img src={BatteryImage} style={{ height: 35, width: 45 }} />
                        <h4 style={{ marginLeft: 20 }}>Liquid Filter bag</h4>
                    </div>
                    <div style={{ height: 3, width: 270, backgroundColor: isSelected === 4 ? "#E58E1A" : "#C8C8C8", marginLeft: 20 }} />
                    {/* <div style={{ flexDirection: "row", display: "flex", alignItems: "center", marginLeft: 20 }} onClick={() => setisSelected(5)}>
                        <img src={TensionImage} style={{ height: 35, width: 45 }} />
                        <h4 style={{ marginLeft: 20 }}>High Tension Terminal Box</h4>
                    </div>
                    <div style={{ height: 3, width: 270, backgroundColor: isSelected === 5 ? "#E58E1A" : "#C8C8C8", marginLeft: 20 }} /> */}
                    <div style={{ flexDirection: "row", display: "flex", alignItems: "center", marginLeft: 20 }} onClick={() => setisSelected(6)}>
                        <img src={HoodImage} style={{ height: 35, width: 45 }} />
                        <h4 style={{ marginLeft: 20 }}>FRP Engine Hood</h4>
                    </div>
                    <div style={{ height: 3, width: 270, backgroundColor: isSelected === 6 ? "#E58E1A" : "#C8C8C8", marginLeft: 20 }} />
                </div>
                <div>
                    {rowsOfThree.map((row, index) => (
                        <div key={index} className="rows">
                            {row.map(item => (
                                item.attributes.map((items) => (
                                    <div key={items.id} className="col" style={{backgroundColor: "#fff", height: 460, textAlign: "left", borderWidth: 1, borderColor: "#000"}}>
                                        {/* Display item details here */}
                                        <img src={items.image !== "" ? items.image : ProductIcon} style={{ height: 150, width: 250 }}/>
                                        <h3>{items.title}</h3>
                                        <p>{items.description}</p>
                                    </div>)
                                )

                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductPage;