import React from 'react';
import { Major1, Major2, ProductIcon1, ProductIcon2, ProductIcon3, ProductIcon4, ProductIcon5, ProductIcon7, Tech1, Tech3, Tech4, landing, landingPageone, landingPagfour, landingPagthree, landingPagtwo, } from '../assests';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const Home = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000
    };
    return (
        <div style={{ backgroundColor: "#29B06710" }}>
            {/* home Image */}
            <div>
                <img src={landing} style={{ height: "730px", width: "100%" }} />
            </div>
            {/* Products we offer */}
            <div className="slider-container">
                <Slider {...settings}>
                    <div>
                        <img src={ProductIcon1} style={{ height: 230, width: 230, padding: 5 }} />
                        <p style={{ textAlign: "center" }}>Blower Cover</p>
                    </div>
                    <div>
                        <img src={ProductIcon2} style={{ height: 230, width: 230, padding: 5 }} />
                        <p style={{ textAlign: "center" }}>Duct Air</p>
                    </div>
                    <div>
                        <img src={ProductIcon3} style={{ height: 230, width: 230, padding: 5 }} />
                        <p style={{ textAlign: "center" }}>Exhaust Manifold</p>
                    </div>
                    {/* <div>
                        <img src={ProductIcon4} style={{ height: 230, width: 230, padding: 5 }} />
                        <p style={{ textAlign: "center" }}>Racing Car Fender</p>
                    </div> */}
                    <div>
                        <img src={ProductIcon5} style={{ height: 230, width: 230, padding: 5 }} />
                        <p style={{ textAlign: "center" }}>Racing Car Fender</p>
                    </div>
                    <div>
                        <img src={landingPageone} style={{ height: 230, width: 230, padding: 5 }} />
                        <p style={{ textAlign: "center" }}>Engine Cover</p>
                    </div>
                    <div>
                        <img src={landingPagfour} style={{ height: 230, width: 230, padding: 5 }} />
                        <p style={{ textAlign: "center" }}>Engine Cover</p>
                    </div>
                    <div>
                        <img src={landingPagthree} style={{ height: 230, width: 230, padding: 5 }} />
                        <p style={{ textAlign: "center" }}></p>
                    </div>
                    <div>
                        <img src={landingPagtwo} style={{ height: 230, width: 230, padding: 5 }} />
                        <p style={{ textAlign: "center" }}>FRP Engine Hood</p>
                    </div>
                </Slider>
            </div>
            {/* TEHNOLOGY */}
            <div>
                <h1 style={{ textAlign: "center" }}>TECHNOLOGY</h1>
                <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                    <div style={{ padding: 5, textAlign: "left", width: 500, marginRight: 100 }}>
                        <h3 style={{ textAlign: "center" }}>FRP - Fiber Reinforced Plastics</h3>
                        <p>Fibre Reinforced Plastic or FRP is a composite material consisting of reinforcing fibres thermosetting resins and other materials such as fillers and pigments may also be present.</p>
                        <p>Fiber Reinforced Plastic (FRP) is one of our areas of expertise since last 20 yrs.</p>
                        <p>Installed Capacity - 800 Ton per annum</p>
                        <p>Components being manufactured are in the following slides</p>
                        <p>We are manufacturing automobile and engineering parts</p>
                    </div>
                    <div>
                        <img src={Tech1} style={{ height: 200, width: 500 }} />
                    </div>
                </div>
                <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                    <div>
                        <img src={Tech3} style={{ height: 200, width: 500, marginRight: 100 }} />
                    </div>
                    <div style={{ padding: 10, textAlign: "left", width: 500 }}>
                        <h3 style={{ textAlign: "center" }}>Plastic Injection Molding</h3>
                        <p>Plastic Injection molding is the fastest way to develop quality plastic products and these products are widely used in different sectors like automobile, electrical, bus body interior and piping industry etc.</p>
                        <p>AECPL has acquired mastery in this technology. We are capable of producing 200 Tons of injection molded components with the use of multiple IM machines having capacity from 25 Tons to 450 tons. We have developed products parts weighing from 0.5 gms to 1.5 kg.</p>
                        <p>This is our prime focus area and it has enabled us entering US market by helping us winning order from US major GreenStar Inc.</p>
                    </div>

                </div>
                <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                    <div style={{ padding: 5, textAlign: "left", width: 500, marginRight: 100 }}>
                        <h3 style={{ textAlign: "center" }}>Polyurethane Foaming</h3>
                        <p>We are manufacturing PU Foaming components for NVH (Noise, Vibration and Heat) insulation      .</p>
                        <p>Machine Capacity - 125 kg/hour</p>
                    </div>
                    <div>
                        <img src={Tech4} style={{ height: 200, width: 500 }} />
                    </div>
                </div>
            </div>

            {/* Some of our Major Customers */}
            <div style={{justifyContent: 'center', alignItems: "center", alignSelf: "center"}}>
                <h1 style={{ textAlign: "center" }}>Some of our Major Customers</h1>
                <img src={Major1} style={{ height: 100, width: 1000, marginTop: 15, marginLeft: 200}} />
                <img src={Major2} style={{ height: 100, width: 1000, marginTop: 40, marginLeft: 200}} />
            </div>
        </div>
    );
};

export default Home;