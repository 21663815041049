import React from 'react';
import { Tech1, TechnologiesHeader, Tech3, Tech4, Tech5, Tech6, Tech7, Tech8, Tech9, TechRing, TechnologiesIcon, TechnologiesText } from '../assests';

const Technologies = () => {
    return (
        <div style={{ backgroundColor: "#29B06710" }}>
            <div>
                <img src={TechnologiesHeader} style={{ height: "730px", width: "100%" }} />
            </div>
            {/* <h1 style={{ textAlign: "center" }}>TECHNOLOGY</h1> */}
            <img src={TechnologiesText} style={{height: "71px",marginLeft: 400, marginTop: "20px"}}/>
            <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                <div style={{ padding: 5, textAlign: "left", width: 500, marginRight: 100 }}>
                    <h3 style={{ textAlign: "center" }}>FRP - Fiber Reinforced Plastics</h3>
                    <p>Fibre Reinforced Plastic or FRP is a composite material consisting of reinforcing fibres thermosetting resins and other materials such as fillers and pigments may also be present.</p>
                    <p>Fiber Reinforced Plastic (FRP) is one of our areas of expertise since last 20 yrs.</p>
                    <p>Installed Capacity - 800 Ton per annum</p>
                    <p>Components being manufactured are in the following slides</p>
                    <p>We are manufacturing automobile and engineering parts</p>
                </div>
                <div>
                    <img src={Tech1} style={{ height: 200, width: 500 }} />
                </div>
            </div>
            <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                <div>
                    <img src={Tech3} style={{ height: 200, width: 500, marginRight: 100 }} />
                </div>
                <div style={{ padding: 10, textAlign: "left", width: 500 }}>
                    <h3 style={{ textAlign: "center" }}>Plastic Injection Molding</h3>
                    <p>Plastic Injection molding is the fastest way to develop quality plastic products and these products are widely used in different sectors like automobile, electrical, bus body interior and piping industry etc.</p>
                    <p>AECPL has acquired mastery in this technology. We are capable of producing 200 Tons of injection molded components with the use of multiple IM machines having capacity from 25 Tons to 450 tons. We have developed products parts weighing from 0.5 gms to 1.5 kg.</p>
                    <p>This is our prime focus area and it has enabled us entering US market by helping us winning order from US major GreenStar Inc.</p>
                </div>

            </div>
            <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                <div style={{ padding: 5, textAlign: "left", width: 500, marginRight: 100 }}>
                    <h3 style={{ textAlign: "center" }}>Polyurethane Foaming</h3>
                    <p>We are manufacturing PU Foaming components for NVH (Noise, Vibration and Heat) insulation      .</p>
                    <p>Machine Capacity - 125 kg/hour</p>
                </div>
                <div>
                    <img src={Tech4} style={{ height: 200, width: 500 }} />
                </div>
            </div>
            <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                <div>
                    <img src={Tech5} style={{ height: 200, width: 500, marginRight: 100 }} />
                </div>
                <div style={{ padding: 20, textAlign: "left", width: 500 }}>
                    <h3 style={{ textAlign: "center" }}>Vacuum Forming</h3>
                    <p>Automotive Vacuum Forming</p>
                    <p>Seat Back covers</p>
                    <p>Battery covers</p>
                    <p>Hattreck panels</p>
                </div>
            </div>
            <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                <div style={{ padding: 20, textAlign: "left", marginRight: 100, width: 500 }}>
                    <h3 style={{ textAlign: "center", }}>Mud Suppression Flap</h3>
                    <p>Mud Suppression flap or rain flap is a component which is made mandatory by ARAI for all commercial vehicles</p>
                    <p>Mud Suppression flap or rain flap has been made mandatory by ARAI for all commercial vehicles.</p>
                    <p>Machine Capacity - 1.5 lakh Sq.ft</p>
                </div>
                <div>
                    <img src={Tech6} style={{ height: 200, width: 500 }} />
                </div>
            </div>
            <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                <div>
                    <img src={Tech7} style={{ height: 200, width: 500, marginRight: 100 }} />
                </div>
                <div style={{ padding: 20, textAlign: "left", width: 500 }}>
                    <h3 style={{ textAlign: "center" }}>Vent Glass Assy</h3>
                    <p>A complete Frame in SS with all inserts, springs, sheet metal parts in SS. Toughend Glass and Aluminium casted Push Button Locking system with weather proof Rubber and sealent.</p>
                </div>
            </div>
            <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>

                <div style={{ padding: 20, textAlign: "left", width: 500, marginRight: 100 }}>
                    <h3 style={{ textAlign: "center" }}>Ring Shroud</h3>
                    <p>This is another of our unique product developed for OEMs</p>
                </div>
                <div>
                    <img src={TechRing} style={{ height: 200, width: 500 }} />
                </div>
            </div>
            <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                <div>
                    <img src={Tech8} style={{ height: 200, width: 500, marginRight: 100 }} />
                </div>
                <div style={{ padding: 20, textAlign: "left", width: 500 }}>
                    <h3 style={{ textAlign: "center" }}>VIN/ Identification Plate</h3>
                    <p>Along with earlier said technologies, we are also Setup new line for manufacturing of VIN / Identification Plates.</p>
                </div>
            </div>
            <div style={{ flexDirection: "row", display: "flex", padding: 15, justifyContent: "center", alignItems: "center" }}>
                <div style={{ padding: 20, textAlign: "left", width: 500, marginRight: 100 }}>
                    <h3 style={{ textAlign: "center" }}>Sheet metal components</h3>
                    <p>Manufacturing parts ranging from .4mm to 8mm thk.</p>
                    <p>Spiral Perforated Tubes for Lube Oil Filters</p>
                    <p>Top and Bottom Covers for Filters</p>
                    <p>Hinges and Brackets</p>
                </div>
                <div>
                    <img src={Tech9} style={{ height: 200, width: 500 }} />
                </div>
            </div>
        </div>
    );
};

export default Technologies;