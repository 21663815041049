export const logo = require("./Assests/Group5.png");
export const landing = require("./Assests/application_CommercialVehicle.jpeg")
export const ProductIcon = require("./Assests/Untitleddesign.png")
export const ProductIcon1= require("./Assests/_MG_0985.png")
export const ProductIcon2= require("./Assests/_MG_1002.png")
export const ProductIcon3= require("./Assests/_MG_1023.png")
export const ProductIcon4= require("./Assests/_MG_1036.png")
export const ProductIcon5= require("./Assests/_MG_1040.png")
export const ProductIcon6= require("./Assests/_MG_1042.png")
export const ProductIcon7= require("./Assests/_MG_1168.png")
export const TechnologiesIcon = require("./Assests/rollerimage.jpg")
export const Major1= require("./Assests/Major1.png")
export const Major2 = require("./Assests/Major2.png")
export const Tech1 = require("./Assests/Tech1.png")
export const Tech2 = require("./Assests/Tech2.png")
export const Tech3 = require("./Assests/Tech3.png")
export const Tech4 = require("./Assests/Tech4.png")
export const Tech5 = require("./Assests/Tech5.png")
export const Tech6 = require("./Assests/Tech6.png")
export const Tech7 = require("./Assests/Tech7.png")
export const Tech8 = require("./Assests/Tech8.png")
export const Tech9 = require("./Assests/Tech9.png")
export const TechRing = require("./Assests/Ring.png")
export const ProductImage = require("./Assests/ProductInage.png")
export const MudImage = require("./Assests/mud.png")
export const AutomotiveImage = require("./Assests/automotive.png")
export const ExcavatorImage = require("./Assests/excavator.png")
export const BatteryImage = require("./Assests/battery.png")
export const HoodImage = require("./Assests/hood.png")
export const TensionImage = require("./Assests/tension.png")
export const aboutUs = require("./Assests/aboutUs.png")
export const object_img = require("./Assests/Object.png")
export const contact_img = require("./Assests/Contact.png")
export const rectangle_img = require("./Assests/Rectangle_1.png")
export const mud_1 = require("./Assests/mud_1.png")
export const mud_2 = require("./Assests/mud_2.png")
export const mud_3 = require("./Assests/mud_3.png")
export const auto_1 = require("./Assests/auto_1.png")
export const auto_2 = require("./Assests/auto_2.png")
export const auto_3 = require("./Assests/auto_3.png")
export const part_1 = require("./Assests/part_1.png")
export const part_3 = require("./Assests/part_3.png")
export const part_4 = require("./Assests/part_4.png")
export const battery_1 = require("./Assests/battery_1.png")
export const battery_2 = require("./Assests/battery_2.png")
export const Graph = require("./Assests/Graph.png")
export const AboutUsIcon1 = require("./Assests/AlfaRomeo.png")
export const AboutUsIcon2 = require("./Assests/Audi.png")
export const AboutUsIcon3 = require("./Assests/BMW.png")
export const AboutUsIcon4 = require("./Assests/Bugatti.png")
export const AboutUsIcon5 = require("./Assests/Citroen.png")
export const AboutUsIcon6 = require("./Assests/Ferrari.png")
export const AboutUsIcon7 = require("./Assests/GT-R.png")
export const AboutUsIcon8 = require("./Assests/Honda.png")
export const AboutUsIcon9 = require("./Assests/Hyundai.png")
export const AboutUsIcon10 = require("./Assests/IranKhodro.png")
export const AboutUsIcon11 = require("./Assests/Jaguar.png")
export const AboutUsIcon12 = require("./Assests/Lamborghini.png")
export const AboutUsIcon13 = require("./Assests/Mazda.png")
export const AboutUsIcon14 = require("./Assests/MercedesBenz.png")
export const AboutUsIcon15 = require("./Assests/Mitsubishi.png")
export const AboutUsIcon16 = require("./Assests/Nissan.png")
export const AboutUsIcon17 = require("./Assests/Peugeot.png")
export const AboutUsIcon18 = require("./Assests/Porsche.png")
export const AboutUsIcon19 = require("./Assests/Subaru.png")
export const AboutUsIcon20 = require("./Assests/Suzuki.png")
export const CircleLogo = require("./Assests/CircleLogo.jpeg")
export const EngineIcon = require("./Assests/image_fir.png")
export const TechnologiesHeader = require("./Assests/technologiesHeader.png")
export const TechnologiesText = require("./Assests/TECHNOLOGY.png")
export const productHeaderOne = require("./Assests/div.slide__inner.png")
export const productHeaderSecond = require("./Assests/PictureProduct.png")
export const productHeaderThird = require("./Assests/productHeaderThird.png")
export const productHeaderFourth = require("./Assests/PictureBatteryPage.png")
export const landingPageone = require("./Assests/landingPageone.png")
export const landingPagfour = require("./Assests/landingPagfour.png")
export const landingPagthree = require("./Assests/landingPagthree.png")
export const landingPagtwo = require("./Assests/landingPagtwo.png")
export const landingpagesix = require("./Assests/landingpagesix.png")
export const aboutUstext = require("./Assests/aboutUstext.png")
export const abouttext = require("./Assests/abouttext.png")
export const Photoabout = require("./Assests/Photo.png")
export const PhotoImageOne = require("./Assests/imageace1.png")
export const PhotoImageTwo = require("./Assests/imageace2.png")
export const PhotoImageThree = require("./Assests/imageace3.png")

