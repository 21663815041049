import { EngineIcon, ProductIcon2, auto_1, auto_2, auto_3, battery_1, battery_2, landingpagesix, mud_1, mud_2, mud_3, part_1, part_3, part_4, productHeaderFourth, productHeaderOne, productHeaderSecond, productHeaderThird, PhotoImageOne, PhotoImageTwo, PhotoImageThree } from "../assests";

export const data = [
    {
        id: 1,
        value: "Mud Suppression Flaps",
        headerImage: productHeaderOne,
        attributes: [
            {
                id: 1,
                title: "Mud Suppression Flap",
                image: mud_1,
                description: "Mud Suppression flap or rain flap has been made mandatory by ARAI for all commercial vehicles."
            },
            {
                id: 2,
                title: "Water Suppression Flap",
                image: mud_2,
                description: "Water Suppression Flaps offered are precision designed and developed Injection moulding plastic components that are known for superior finish features and high durability standards. These are manufactured in different weight options and are based extensively research based in-house technology."
            },
            {
                id: 3,
                title: "Spray Suppression Mud Flaps",
                image: mud_3,
                description: "made available by us come precision designed & constructed so as to effectively perform the function of protecting them from road splash as well as road debris that get thrown up on the lower inside cavities by the tyres. Here, our expertise lies in making these mud flaps available in different size and finish options so as to perfectly match up with the end usage needs in different vehicles."
            },
        ]
    },
    {
        id: 2,
        value: "FRP Automotive Products",
        headerImage: productHeaderSecond,
        attributes: [
            {
                id: 1,
                title: "FRP BUS Parts",
                description: "Our organization is engaged in offering a superior range of FRP Bus Parts to our clients, which is manufactured using best grade raw materials and advanced technology.",
                image: auto_1
            },
            {
                id: 2,
                title: "Pultrusion Products",
                description: "We ACE Engineering and Chemicals Pvt Ltd  are one of the leading manufacturer of all types of Pultrusion Products for varied applications. We have years of experience and expertise to cater needs for various industries",
                image: auto_2
            },
            {
                id: 3,
                title: "Railways window frame",
                description: "Railway Window Frame offered by us feature use of superior grade FRP construction that makes these superior in terms of finish as well as durable & long-lasting in performance. These FRP based frames are backed by rich manufacturing experience and accurate quality control that make these covers long-lasting in terms of performance. Further, these are also resistant to erosion and corrosion, have light weight finish as well as carry excellent dimension stability.",
                image: auto_3
            }
        ]
    },
    {
        id: 3,
        value: "Excavator Parts",
        headerImage: productHeaderThird,
        attributes: [
            {
                id: "1",
                description: "We provide Innovative exterior and interior aesthetic parts for Earth Moving Machines/Equipments to our clients as per their requirement. Our range includes dash boards, bumpers, consoles, canopies, fenders, engine covers, bonnet cover, radiator shrouds, battery box, handles, gear levers, and knobs etc.",
                title: "Excavator Parts",
                image: part_1,
            },
            {
                id: "2",
                description: "Automotive Air Duct offered by us are in FRP and HDPE Blow moulding for Proto and regular production.",
                title: "Automotive Air Ducts in FRP and Blow Moulding",
                image: ProductIcon2,
            },
            {
                id: "3",
                description: "Roof Canopy in RTM Process in FRP composite material for Earth Moving Machines",
                title: "Roof canopy",
                image: part_3,
            },
            {
                id: "4",
                description: "Engine Cover for Earth Moving Machine in RTM process along with NVH foam and Flame retardant Material,",
                title: "Engine Cover",
                image: part_4,
            },
        ]
    },
    {
        id: 4,
        value: "Liquid Filter bag",
        headerImage: productHeaderFourth,
        attributes: [
            {
                id: "1",
                description: (
                    <>
                      Pleated Filter Cartridges are constructed of three polypropylene melt-blown superfine fiber membranes with pleated fabric differed by fiber micron.
                      <br />
                      The filtration area is 10 times larger than normal depth filter cartridge.
                      <br />
                      <strong>Specification:</strong>
                      <br />
                      Filter Media: Pleated Polypropylene
                      <br />
                      Micron Rating: 0.10, 0.22, 0.45, 1.00, 5.00
                      <br />
                      Size (inch): (2.5 x 10), (2.5 x 20), (2.5 x 30)
                    </>
                  ),
                title: "PLEATED FILTER CARTRIDGES",
                image: PhotoImageOne,
            },
            {
                id: "2",
                description: (
                    <>
                      Filter bags are specially designed for high flow rate and easy cleaning, which increase its demand in market.
                      <br />
                      <strong>Specification:</strong>
                      <br />
                      Polypropylene (PP), Polyester (PE), Nylon Mesh
                      <br />
                      Micron: 1, 5, 10, 25, 50, 100, 200
                      <br />
                      Size: (4 x 10), (4 x 20), (7 x 17), (7 x 32) custom made
                      <br />
                      Ring: Polypropylene (PP), Polyester (PE), Nylon Mesh, SS, GI
                    </>
                  ),
                title: "RANGE OF FILTER BAGS",
                image: PhotoImageTwo,
            },
            {
                id: "3",
                description: "Centrifuge Filter Bags are robust in removing the gelatinous particles and solid particles. These bags are available in cotton fabric and are versatile with custom. ",
                title: "CENTRIFUGAL FILTER BAG",
                image: PhotoImageThree,
            },
        ]
    },
    // {
    //     id: 5,
    //     value: "High Tension Terminal Box",
    // headerImage: "",
    //     attributes: [
    //         {
    //             id: "1",
    //             description: "                                             ",
    //             title: "",
    //             image: "",
    //         },        
    //     ]
    // },
    {
        id: 6,
        value: "FRP Engine Hood",
        headerImage: landingpagesix,
        attributes: [
            {
                id: "1",
                description: "FRP is valued in automotive applications because it's lightweight, strong, and resistant to corrosion. These hoods offer benefits such as improved fuel efficiency due to reduced weight, better performance, and durability compared to traditional metal hoods. Additionally, they can be molded into complex shapes, allowing for unique and aerodynamic designs.",
                title: "Frp engine hood.",
                image: EngineIcon,
            },
        ]
    },
]